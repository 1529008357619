import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { API_CLIENT } from '../api/client';
import { useEffect } from "react";
import { useState } from "react";

import ItemCard from './ItemCard';


export default function Inventory() {

    return (
            <ItemCard />
    )
}