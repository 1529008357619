import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, redirect } from 'react-router-dom';

//components
import Layout from '../components/Layout';
import Login from './Login';
import Dashboard from './Dashboard';
import Notfound from './Notfound';
import Account from './Account';
import useToken from '../useToken';
import { apiClient } from '../api/client';
import EmailVerification from './EmailVerification';
//import Onboard from './onboard';
import ResetPassword from './ResetPassword'
import AdminDashboard from './admin/Dashboard';
import AdminUser from './admin/User';
import Withdraw from './Withdraw';

const UNAUTHED_ROUTES = ["/login", "/onboard", "/verifyEmail", "/resetPassword"]

function App() {
    const { token, setToken } = useToken();

    let pathname = window.location.pathname;
    if (token == null && !UNAUTHED_ROUTES.includes(pathname)) {
        window.location.assign("/login");
        return;
    }

    return (
        <div className='wrapper'>
            <Router>
                <Routes>
                    <Route path='/' element={<Layout setToken={setToken} />}>
                        <Route index element={<Dashboard />} />
                        <Route path='*' element={<Notfound />} />
                        <Route path='/settings' element={<Account />} />
                        <Route path='/withdraw' element={<Withdraw />} />
                    </Route>
                    <Route path='/verifyEmail' element={<EmailVerification />} />
                    <Route path='/resetPassword' element={<ResetPassword />} />
                    <Route path='/admin' element={<Layout setToken={setToken} />}>
                        <Route index element={<AdminDashboard />} />
                        <Route path='user' element={<AdminUser />} />
                    </Route>
                    <Route path='/login' element={<Login setToken={setToken} />} />

                </Routes>
            </Router>

        </div>

    )
}

export default App;