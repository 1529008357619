import React from 'react';
import { Modal, Button, Form, InputGroup, FormText} from 'react-bootstrap';
import StatusLog from '../components/StatusLog'

const PopupModal = ({ item, show, handleClose, setBadgeStyle, checkBadgeStatus}) => {

  function renderItemImage(param) {
    if (param) {
        return "https://imagedelivery.net/tR818LzxO6wAoQipdR2H9Q/" + param + "/public";
    } else {
        return 'https://via.placeholder.com/150';
    }
}



  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        
        <Modal.Title>

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='modal-img-container mb-3'>
          <img src={renderItemImage(item.image_url)} style={{width:'100%'}} alt={item.description} />
        </div>

        <div className='modal-info-content'>
        <div className='modal-item-title'>
          <div>
          <h4 style={{textAlign: 'center'}}>{item.description}</h4>
          </div>
          <div className={setBadgeStyle(item.status)} >
              <p className='storable-badge-text'>{checkBadgeStatus(item.status)}</p>
          </div>
        </div>
        <InputGroup size="sm" className="mb-3">

        <InputGroup.Text id="inputGroup-sizing-sm">ID</InputGroup.Text>
        <Form.Control
          aria-label="item id"
          placeholder={item.id.substring(28) + "/" + item.identifier }
          aria-describedby="inputGroup-sizing-sm"
          readOnly
        />
      </InputGroup>


        <InputGroup>
        <InputGroup.Text>Notes</InputGroup.Text>
        <Form.Control style={{height:'100px'}} as="textarea" aria-label="Item Notes" defaultValue={item.notes} />
        </InputGroup>
      <StatusLog item={item} />
      </div>


      </Modal.Body>
      <Modal.Footer>
        <Button>Save</Button>
      </Modal.Footer>

    </Modal>
  );
};

export default PopupModal;