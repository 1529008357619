import { Container } from "react-bootstrap";


export default function Notfound() {
    return(
        <>
            <Container className='mt-3' style={{textAlign:'center'}}>
                <h1>404</h1>
                <h2>Page not found</h2>
                <h3 style={{marginBottom:'2rem'}}>Woops! We couldnt find that page.</h3>
                <a href='/'>Back to dashboard</a>



            </Container>
        </>
    )
}