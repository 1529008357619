import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function CheckoutFooter({selectedItemIds, selectionMode}) {

    return (
            <>
                {
                    selectionMode === true && selectedItemIds.length >= 1 &&
                    <div className='checkout-section'>
                        <Link to={`/withdraw?selectedItemIds=${selectedItemIds.join(",")}`}>
                            <button className='storable-btn'>Checkout {selectedItemIds.length} Items</button>
                        </Link>

                    </div>

                }
            </>
    )
}