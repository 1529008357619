import { Container } from "react-bootstrap";
import { setPassword, AuthKeyNotFound } from "../api/client"
import NewPasswordForm from "../components/NewPasswordForm";

export default function PasswordReset() {
    const searchParams = new URLSearchParams(document.location.search);
    const authId = searchParams.get("id");

    if (!authId) {
        window.location.assign('/login');
    }

    async function submitFn(password) {
        try {
            await setPassword(authId, password);

            return true;
        } catch (e) {
            if (e instanceof AuthKeyNotFound) {
                return false;
            } else {
                throw e;
            }
        }
    }

    return (
        <Container className='mt-3' style={{ textAlign: 'center' }}>
            <h1 className='mb-3 mt-3'>Storable</h1>

            <NewPasswordForm handleSubmission={submitFn} />

            <h3>Congratulations! You're account has been verified.</h3>
        </Container>
    )
}
