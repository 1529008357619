/*
    This is where users will create there first booking.

        Options
            - Storable Tub
                They will be able to select the amount of tubs they'd like stored. Users can add more later. 
            - Virtual Units
                Customers select the unit size, and can put as many items until it is full. At which point no new items can be added, or they must upgrade.
    

*/

import { Button, Container } from "react-bootstrap";





export default function NewBooking(){

    return(
          
            <div className='container booking-card'>
                <h1 className='booking-header'>Looks like you don't have any items</h1>
                <p>Contact us to schedule a pickup</p>
                    <a href='/contact'>
                    <Button type='submit' className='storable-btn'>Book Appointment</Button>
                    </a>

            </div>
        
    )
}