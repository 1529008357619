import React from "react"
import { Outlet } from 'react-router-dom';
import ServerStatus from "./ServerStatus";


import Header from "./Header";

export default function Layout({ setToken }) {
    return (<>
        <ServerStatus />
        <Header setToken={setToken} />
        <Outlet />
  
    </>
    )
}