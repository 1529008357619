import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class ApiClient {
   constructor(baseURL) {
      this.client = axios.create({
         baseURL,
         withCredentials: true,
      });
   }

   /**
    * Sets the token in sessionStorage
    * @param {string | null} token 
    * @returns {null}
    */
   setToken(token) {
      if (token === null) {
         sessionStorage.removeItem("sessionId");
      } else {
         sessionStorage.setItem("sessionId", token);
      }
   }

   getToken() {
      return sessionStorage.getItem("sessionId");
   }

   async makeRequestWithHeaders(method, url, config) {
      if (config) {
         if (config.headers) {
            config.headers['Authorization'] = `Bearer ${this.getToken()}`;
         } else {
            config.headers = { 'Authorization': `Bearer ${this.getToken()}` };
         }
      } else {
         config = { headers: { 'Authorization': `Bearer ${this.getToken()}` } };
      }

      config.url = url;
      config.method = method;

      return await this.client.request(config);
   }

   async get(url, config) {
      return await this.makeRequestWithHeaders('get', url, config);
   }

   async post(url, data, config = {}) {
      config.data = data;
      return await this.makeRequestWithHeaders('post', url, config);
   }

   async put(url, data, config = {}) {
      config.data = data;
      return await this.makeRequestWithHeaders('put', url, config);
   }

   async patch(url, data, config = {}) {
      config.data = data;
      return await this.makeRequestWithHeaders('patch', url, config);
   }

   async delete(url, data, config = {}) {
      console.log(this);
      config.data = data;
      return await this.makeRequestWithHeaders('delete', url, config);
   }

   /**
    * Checks to see if the server is both reachable and active
    * @returns {bool} Returns if the server can be reached
    */
   async serverStatus() {
      try {
         let response = await this.client.get("d");
         console.log(response.data);

         return response.data === "OK";
      } catch (e) {
         console.err(e);
         return false;
      }
   }

   /**
    * Creates a session token with the backend, and stores it in the sessionStorage.
    * @param {string} email - The user's email
    * @param {string} password - The user's password
    * @returns {bool} Returns whether the login was successful
    */
   async login(email, password, setToken) {
      try {
         let response = await this.client.post("/user/session", { email, password });

         if (response.err) {
            console.err(response);
            return false;
         } else {
            if (response.status) {
               console.log("ApiClient::login -", response.data);
               sessionStorage.setItem("sessionId", response.data);
               setToken(response.data)
               return true;
            } else {
               return false;
            }
         }
      } catch (e) {
         console.error(e);
         return false;
      }
   }

   /**
    * 
    * @param {string} firstName The user's first name
    * @param {string} lastName The user's last name
    * @param {string} email The user's email
    * @param {string} password The user's password, in plaintext
    * @returns {string|null} The user's uuid, or null on error
    */
   async createUser(firstName, lastName, email, password) {
      try {
         let response = await this.client.post("/user/create", { firstName, lastName, email, password });
         return response.data
      } catch (e) {
         console.error(e);
         return null
      }
   }

   /**
    * Verifies a user on the backend when given the correct authentication key
    * @param {string} authKey The key sent to the user's email
    * @returns {bool} Whether or not the user was successfully authenticated
    * @throws Throws if axios returns an error that isn't a status code outside 2xx
    */
   async verifyUser(authKey) {
      try {
         let _response = await this.client.patch(`/user/verify/${authKey}`);
         return true;
      } catch (e) {
         console.error(e);
         return false;
      }
   }


   /**
    * Requests that the server sends a verification email to the currently signed in user
    */
   async sendVerificationEmail() {
      await this.post("/user/verify/sendEmail");
   }

   /**
    * Updates the currently signed in user's address
    * @param {string} line1 
    * @param {string} line2 
    * @param {number} postcode 
    * @param {string} city 
    */
   async updateAddress(line1, line2, postcode, city) {
      await this.put("/user/address", { line1: line1 || "", line2: line2 || "", postcode: postcode || "", city: city || "" });
   }

   /**
    * Removes the session token from the sessionStorage
    * @returns {bool} Returns whether the logout was successful
    */
   async logout(setToken) {
      setToken(null);
      sessionStorage.removeItem("sessionId");
      return true;
   }

   /**
    * Returns the user with the given id
    * @param {string} id - The user's uuid 
    * @returns {Promise<User>}
    */
   async getUser(id) {
      return (await this.get(`/user/${id}`)).data;
   }

   async deleteUser(id) {
      return (await this.delete(`/user/${id}`)).data;
   }

   async getAuthedUser() {
      try {
         let response = await this.get('/user/authenticated');
         console.log(response.data);
         return response.data;
      } catch (e) {
         if (e.response.status == 401 /* Unauthorized */) {
            console.error(e);
            return null;
         } else {
            throw e;
         }
      }
   }

   async getAuthedUserItems() {
      let response = await this.get('/user/authenticated/items');
      return response.data.filter((item) => item.status != "ARCHIVED").reverse();
   }

   async getUserItems(id) {
      return (await this.get(`/user/${id}/items`)).data;
   }

   async adminUserSearch(firstName, lastName, email) {
      return await this.get(`/admin/find?firstName=${firstName}&lastName=${lastName}&email=${email}`);
   }

   async getBillingUrl() {
      return (await this.post('/user/startStripeSession')).data
   }

   async sendResetEmail(email) {
      try {
         let _response = this.post('/resetPassword/sendEmail', { email });
         return true;
      } catch (e) {
         return false;
      }
   }

   async resetPassword(resetKey, newPassword) {
      try {
         let _response = this.post('/resetPassword', { resetKey, newPassword });
         return true;
      } catch (e) {
         return false;
      }
   }
}

/**
 * Sends a password reset email to the given address.
 * The sent key can be used with `setPassword`.
 * @param {string} email 
 * @returns {bool} Whether or not the email was sent successfully. It should only fail if the 
 * email is not already associated with an account.
 */
export async function sendPasswordReset(email) {
   const body = { email };
   const headers = {
      'Content-Type': "application/json",
   };

   const response = await fetch(`${SERVER_URL}/resetPassword/sendEmail`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
   });

   if (response.status === 200) {
      return true;
   } else if (response.status === 404) {
      return false;
   } else {
      throw new Error(`Code: ${response.status}, Message: ${await response.text()}`);
   }
}

/**
 * Sets up an account and sends an activation email to the given address.
 * The sent key can be used with `setPassword`.
 * @param {string} firstName 
 * @param {string} lastName 
 * @param {{ line1: string, line2: string, postalcode: string, city: string}} address 
 * @param {string} email 
 * @returns {Promise<boolean>} True if the account was setup successfully, 
 * false if anything else happens.
 * @throws {ResourceAlreadyExists} If a user with that name already exists.
 */
export async function setupAccount(firstName, lastName, address, email) {
   const body = { firstName, lastName, address, email };
   const headers = {
      'Content-Type': "application/json",
   };

   const response = await fetch(`${SERVER_URL}/user`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
   });

   if (response.status === 200) {
      return true;
   } else if (response.status === 409) {
      throw new ResourceAlreadyExists();
   } else {
      return false;
   }
}

/**
 * Sets the password for an account that's been setup but not been given a password.
 * @param {string} resetKey
 * @param {string} newPassword
 * @returns {Promise<boolean>}
 * @throws {AuthKeyNotFound}
 */
export async function setPassword(resetKey, newPassword) {
   const body = { newPassword, resetKey };
   const headers = {
      'Content-Type': 'application/json',
   };

   const response = await fetch(`${SERVER_URL}/user/resetPassword`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
   });

   if (response.status === 200) {
      return true;
   } else if (response.status === 404) {
      throw new AuthKeyNotFound();
   } else {
      return false;
   }
}

/**
 * Requests the server to withdraw the given list of items.
 * @param {string[]} itemIds A list of the item ids to be withdrawn. 
 * @throws {NoSessionId} 
 * @returns {Promise<boolean>}
 */
export async function withdrawItems(itemIds) {
   const body = { ids: itemIds };
   const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getSessionId()}`,
   };

   const response = await fetch(`${SERVER_URL}/user/withdrawItems`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
   });

   return response.ok;
}

/**
 * Gets the session id.
 * @returns {string}
 * @throws {NoSessionId}
 */
function getSessionId() {
   let id = window.sessionStorage.getItem('sessionId');

   if (id === null || !isUuid(id)) {
      window.sessionStorage.removeItem('sessionId');
      throw new NoSessionId();
   } else {
      return id;
   }
}

function isUuid(val) {
   const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
   return regexExp.test(val);
}

export function getImageUrl(imageId) {
   if (imageId) {
      return "https://imagedelivery.net/tR818LzxO6wAoQipdR2H9Q/" + imageId + "/public";
   } else {
      return 'https://via.placeholder.com/150';
   }
}

/**
 * Checks to see if the given session token is valid. Currently unimplemented.
 * @param {string} session 
 * @returns boolean
 */
export async function verifySession(session) {
   return true;
}

const client = axios.create({
   baseURL: "http://localhost:8080/user"
});

function apiClient(username, password) {
   return new ApiClient("http://localhost:8080")
}

const API_CLIENT = new ApiClient(process.env.REACT_APP_SERVER_URL);

export {
   client,
   apiClient,
   API_CLIENT,
};

export class ResourceDoesNotExist extends Error {
   constructor() {
      super()
   }
}

export class ResourceAlreadyExists extends Error {
   constructor() {
      super()
   }
}

export class AuthKeyNotFound extends Error {
   constructor() {
      super()
   }
}

export class NoSessionId extends Error {
   constructor() {
      super()
   }
}