import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { API_CLIENT } from '../../api/client';

export default function Dashboard() {
    let [results, setResults] = useState([
        { firstName: "Cameron", lastName: "Brownsey", email: "cameron@brownsey.com" },
        { firstName: "Fraser", lastName: "Brownsey", email: "fraser@brownsey.com"}
    ]);

    let [emailSearch, setEmailSearch] = useState("");
    let [firstNameSearch, setFirstNameSearch] = useState("");
    let [lastNameSearch, setLastNameSearch] = useState("");

    async function handleSubmit() {
        let users = await API_CLIENT.adminUserSearch(firstNameSearch, lastNameSearch, emailSearch);
        console.log(users.data);
        setResults(users.data);
    }

    return (
        <>
            <Form>
                <Form.Group>
                    <Form.Label>User Email:</Form.Label>
                    <Form.Control type="email" onChange={(e) => { setEmailSearch(e.target.value) }} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>User First Name:</Form.Label>
                    <Form.Control type="text" onChange={(e) => { setFirstNameSearch(e.target.value) }} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>User Last Name:</Form.Label>
                    <Form.Control type="text" onChange={(e) => { setLastNameSearch(e.target.value) }} />
                </Form.Group>
                <Button variant="primary" onClick={handleSubmit}>Submit</Button>
            </Form>

            <table>
                <tr>
                    <td>Name</td>
                    <td>Email</td>
                </tr>
                {results.map((obj, i) => {return <Row user={obj} key={i} />})}
            </table>
        </>
    )
}

function Row(props) {
    return (
        <tr>
            <td>{props.user.lastName + ', ' + props.user.firstName}</td>
            <td>{props.user.email}</td>
            <td><button>Edit</button></td>
        </tr>
    )
}