import React, { useState } from "react";
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Card from "react-bootstrap/Card"
import { API_CLIENT } from '../api/client'
import ServerStatus from "../components/ServerStatus";
import CasperLogoBlue from '../assets/images/CasperLogoBlue.svg';
//// bit of a guide 
//// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications#step-1-building-a-login-page
///API HERE
//async function loginUser(token) {
//let response = await API_CLIENT.login(email,password)
//}

async function createAccount(newemail, newpassword, firstname, lastname) {
    return await API_CLIENT.createUser(firstname, lastname, newemail, newpassword);
}

async function loginUser(email, password, setToken) {
    if (await API_CLIENT.login(email, password, setToken)) {
        return true;
    } else {
        return false;
    }
}

async function logoutUser(setToken) {
    API_CLIENT.logout();
}

export default function Login({ setToken }) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loginError, setLoginError] = useState();





    const handleSubmitLogin = async e => {
        e.preventDefault();
        if (!await loginUser(email, password, setToken)) {
            console.log('wrong login')
            setLoginError(true);
        } else {
            console.log('logged in');
            window.location.assign('/');
        }
    };




    return (
        <>
            <ServerStatus />
            <div className="col d-flex justify-content-center storable-login-container storable-background-wrapper">

                <Card className="mt-5 storable-login-card">
                    <img style={{marginBottom: '20px'}} src={CasperLogoBlue} />
                    <Form id='login-form' onSubmit={handleSubmitLogin} style={{ display: 'block' }}>
                        <h2>Login</h2>

                        <Form.Group className="mb-3" controlId="formEmail">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email address"
                                className="mb-3"
                            >
                                <Form.Control type="email" placeholder="name@example.com" onChange={e => setEmail(e.target.value)} />
                            </FloatingLabel>

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPassword">
                            <FloatingLabel controlId="floatingPassword" label="Password">
                                <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                            </FloatingLabel>
                        </Form.Group>

                        {
                            loginError === true && <div className="alert alert-danger mt-3" role="alert"> Username or password is incorrect. Please contact us if this issue persists.</div>
                        }
                        <Form.Group className="mt-4" controlId="formLogin">
                            <Button className="storable-login-btn" variant="primary" type="submit">
                                Login
                            </Button>

                        </Form.Group>


                    </Form>

                </Card>

            </div>
        </>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}