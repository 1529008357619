import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Alert } from "react-bootstrap";

export default function NewPasswordForm({ handleSubmission }) {
  const [password, setPassword] = useState("");

  const [passwordMatches, setPasswordMatches] = useState(false);
  const [tryAgain, setTryAgain] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [validPassword, setValidPassword] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState('temp');

  const [hasMinimum, setHasMinimum] = useState(false);
  const [hasCapital, setHasCapital] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecial, setHasSpecial] = useState(false);

  async function handleSubmit(ev) {
    ev.preventDefault();

    if (!(await handleSubmission(password))) {
      setPassword("");
      setTryAgain(false);
    }
  }


  useEffect(() => {
    if (hasCapital && hasMinimum && hasNumber && hasSpecial) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
  }, [hasCapital, hasMinimum, hasNumber, hasSpecial]);

  useEffect(() => {
    if (confirmPassword === password) {
      console.log("Password match");
      setPasswordMatches(true);
    } else {
        setPasswordMatches(false);
    }

    if (password.length > 7) {
      setHasMinimum(true);
    } else {
      setHasMinimum(false);
    }
    if (/[A-Z]/.test(password)) {
      setHasCapital(true);
    } else {
      setHasCapital(false);
    }
    if (/\d/.test(password)) {
      setHasNumber(true);
    } else {
      setHasNumber(false);
    }

    if (/[!@#$%^&*()_+[\]{};':"\\|,.<>/?]/.test(password)) {
      setHasSpecial(true);
    } else {
      setHasSpecial(false);
    }
  }, [confirmPassword, password]);



  async function savePassword(ev) {
    handleValidPassword(password)

    setPassword(ev);
  }
  async function handleValidPassword(ev) {

  }



  async function handleConfirmPassword(ev) {
    setConfirmPassword(ev);
    if (confirmPassword === password) {
      setPasswordMatches(true);
    } else {
      setPasswordMatches(false);
    }
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formNewPassword">
          <FloatingLabel
            controlId="floatingInput"
            label="Set Password"
            className="mb-3"
          >
            <Form.Control
              placeholder="Set Password"
              className={validPassword ? "is-valid" : "is-invalid"}
              type={showPassword ? "text" : "password"}
              onKeyUp={(e) => savePassword(e.target.value)}
            />
            <div className="mx-auto mt-3" style={{ width: "30%" }}>
              <p className="">Password must contain: </p>
              <p
                className={
                  hasMinimum
                    ? "valid-feedback d-block text-start"
                    : "invalid-feedback d-block text-start"
                }
              >
                More than 8 characters.
              </p>
              <p
                className={
                  hasCapital
                    ? "valid-feedback d-block text-start"
                    : "invalid-feedback d-block text-start"
                }
              >
                {" "}
                At least one uppercase letter.
              </p>
              <p
                className={
                  hasNumber
                    ? "valid-feedback d-block text-start"
                    : "invalid-feedback d-block text-start"
                }
              >
                At least one number.
              </p>
              <p
                className={
                  hasSpecial
                    ? "valid-feedback d-block text-start"
                    : "invalid-feedback d-block text-start"
                }
              >
                At least one special character.
              </p>
            </div>

            {/* <Form.Control placeholder='Set Password' type={showPassword ? 'text' : 'password'} onChange={e => setPassword(e.target.value)} /> */}
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formNewPassword">
          <FloatingLabel
            controlId="floatingInput"
            label="Confirm Password"
            className="mb-3"
          >
            <Form.Control
              placeholder="Confirm Password"
              className={passwordMatches ? "is-valid" : "is-invalid"}
              type={showPassword ? "text" : "password"}
              onKeyUp={(e) => handleConfirmPassword(e.target.value)}
            />
          </FloatingLabel>

          <a onClick={handleTogglePasswordVisibility}>
            <u>{showPassword ? "Hide" : "Show"} Passwords</u>
          </a>
        </Form.Group>

        <Form.Group className="mt-4" controlId="formSubmitNewPassword">
          <Button className="casper-btn-bs" variant="primary" type="submit">
            Set Password
          </Button>
        </Form.Group>
      </Form>

      <p hidden={tryAgain}>Try again</p>
    </div>
  );
}
