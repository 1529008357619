import { useState, useEffect } from "react";
import { API_CLIENT } from "../api/client";

export default function Welcome() {
    const [user, setUser] = useState();
    const [isFetched, setIsFetched] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            setUser(await API_CLIENT.getAuthedUser());
            setIsFetched(true);
        }
        fetchUser();
    }, []);


    if (isFetched) {
        return (
            <div className='storable-welcome-banner'>
                <h5>Welcome, {user.firstName}</h5>
            </div>

        )
    }


    return (
        <div className='storable-welcome-banner'>
            <h5>Welcome, loading...</h5>
        </div>
    )
}