import { Form } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { API_CLIENT } from '../../api/client'
import { useState } from "react";

export default function UserDetails() {
    const [lineOne, setLineOne] = useState();
    const [lineTwo, setLineTwo] = useState();
    const [postcode, setPostcode] = useState();
    const [city, setCity] = useState();


    const handleSubmitDetails = async e => {
        e.preventDefault();
        await API_CLIENT.updateAddress(lineOne, lineTwo, postcode, city);
        window.location.reload(false);
    };
    return(
        <div className='page-center'>

            <div className="col d-flex justify-content-centre userdetails">
                <div>
                    <h1>Welcome to Storable</h1>
                    <h2>We need some more infomation before we can get started.</h2>


                    <Form id='addDetailsForm' onSubmit={handleSubmitDetails}>
                            <Form.Group className="mb-3" controlId="formAddress">
                                <FloatingLabel controlId="floatingInput" label="Line 1" className="mb-3">
                                    <Form.Control type="text" placeholder="123 Smith St, Auckland, New Zealand" autocomplete="address"  onChange={e => setLineOne(e.target.value)}/>
                                </FloatingLabel>

                                <FloatingLabel controlId="floatingInput" label="Line 2" className="mb-3">
                                    <Form.Control type="text" placeholder="123 Smith St, Auckland, New Zealand" autocomplete="address" onChange={e => setLineTwo(e.target.value)}/>
                                </FloatingLabel>

                                <FloatingLabel controlId="floatingInput" label="Postcode" className="mb-3">
                                    <Form.Control type="text" placeholder="123 Smith St, Auckland, New Zealand" autocomplete="address" onChange={e => setPostcode(e.target.value)}/>
                                </FloatingLabel>
                                
                                <FloatingLabel controlId="floatingInput" label="City" className="mb-3">
                                    <Form.Control type="text" placeholder="123 Smith St, Auckland, New Zealand" autocomplete="address" onChange={e => setCity(e.target.value)}/>
                                </FloatingLabel>
                            </Form.Group>
                            <button className="storable-btn">Submit</button>
                        </Form>
                </div>
            </div>
        </div>
    )
}