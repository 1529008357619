import { redirect, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { API_CLIENT, getImageUrl, withdrawItems } from "../api/client";

export default function Withdraw() {
    const [items, setItems] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const location = useLocation();
    useEffect(() => {
        const fetchItems = async () => {
            setItems(await API_CLIENT.getAuthedUserItems());
        }
        fetchItems();

    }, []);
    const params = new URLSearchParams(location.search);
    const selectedItemIds = params.get("selectedItemIds");

    const filteredItems = items.filter(item => selectedItemIds.includes(item.id));

    async function handleConfirm(ev) {
        setDisableSubmit(true);
        if (await withdrawItems(params.get("selectedItemIds").split(','))) {
            console.log("Happy path");
            window.location.assign('/');
        } else {
            console.log("Sad path");
            console.error("An unknown error occurred when withdrawing items.");
        }
    }

    return (
        <>
            <div className='checkout-list'>
                {filteredItems.map((item) => (
                    <div className='checkout-list-item' key={item.id}>
                        <img className='card-img-top storable-card-img' style={{ height: '12rem', objectFit: 'cover', borderRadius: '3px 3px 0px 0px' }} src={getImageUrl(item.image_url)} />

                        <div className='checkout-list-text'>
                            <p>{item.description}</p>

                            <p>{item.status}</p>
                        </div>
                    </div>
                ))}

                <div className='checkout-list-controls'>
                    <button style={{ width: '15%' }} className='storable-btn storable-btn-secondary'><a href="/">Cancel</a></button>
                    <button style={{ width: '75%' }} className='storable-btn' onClick={handleConfirm} disabled={disableSubmit}>Confirm Selection</button>
                </div>
            </div>
        </>
    )
}