import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import { Dropdown } from 'react-bootstrap';
import axios from "axios";
import Form from 'react-bootstrap/Form';
import { API_CLIENT } from '../api/client';
import logo from '../assets/images/CasperLogoWhitelogowhite.png'

function logout(setToken) {
    API_CLIENT.logout(setToken);
}

async function navigateToBilling() {
    let url = await API_CLIENT.getBillingUrl();
    window.location.assign(url);
}

export default function Header({ setToken }) {
    return <nav className='navbar bg-light storable-nav'>
        
        <Container>
            <a className='navbar-brand' href='/'>
                <img src={logo} alt='logo'/>
            </a>
            {/*<a href='Account' className='btn btn-success storable-btn'>Account</a> */}
            <Dropdown className='storable-nav-dropdown'>
                <Dropdown.Toggle variant='success' id='dropdown-basic' className='storable-btn'>
                    Account
                </Dropdown.Toggle>
                <Dropdown.Menu>

                    <Dropdown.Item href="/settings">Settings</Dropdown.Item>

                    <Dropdown.Item onClick={navigateToBilling}>Billing</Dropdown.Item>
                    <Dropdown.Item className="storable-dropdown-divider"></Dropdown.Item>
                    <Dropdown.Item onClick={() => logout(setToken)}>Logout</Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>
        </Container>
    </nav>
}

//mongodb+srv://<username>:<password>@cluster0.8xznmyt.mongodb.net/test

//mongodb-uri = "mongodb+srv://fraser:Vaila1691@cluster0.8xznmyt.mongodb.net/?retryWrites=true&w=majority"


