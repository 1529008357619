import { Button, OverlayTrigger } from "react-bootstrap";
import { Form, InputGroup, Tooltip } from "react-bootstrap";
export default function ItemToolBar({ selectionMode, handleSelectButtonClick, searchTerm, setSearchTerm }) {
    function clearSearch() {
        setSearchTerm('');
    }
    return(

        <div className='item-toolbar'>
            <div>
            <InputGroup className="mb-3">
                <Form.Control
                placeholder="Search..."
                aria-label="Item Search"
                aria-describedby="basic-addon2"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Button variant="outline-secondary" id="button-addon2" onClick={clearSearch}>
                Clear
                </Button>
            </InputGroup>
            </div>

        <div className='item-select-radio'>
            <Form.Check
                type='switch'
                id='select-switch'
                label='Withdraw Items'
                checked={selectionMode}
                onChange={handleSelectButtonClick}

            />
        </div>
       

        </div>
    )
}