import { Container, Row, Col, Card } from "react-bootstrap"
import { API_CLIENT } from "../api/client";
import { useEffect, useState } from "react";

/*
    - account details (name, email, address)
    - password reset
    - Payment settings
*/

export default function Account() {
    const [user, setUser] = useState();
    const [isFetched, setIsFetched] = useState(false);


    useEffect(() => {
        const fetchUser = async () => {
            setUser(await API_CLIENT.getAuthedUser());
            setIsFetched(true);
        }
        fetchUser();
    }, []);

    if (!isFetched) {
        return (
            <h1>Loading....</h1>
        )
    }

    return(
        <div className='container booking-card account-div'>
            <h1>Account Details</h1>
            <table>
                <tr>
                    <td>Name: </td>
                    <td> {user.firstName} {user.lastName}</td>
                </tr>

                <tr>
                    <td>Email: </td>
                    <td> {user.email}</td>
                </tr>

                <tr>
                    <td>Phone:</td>
                    <td>{user.phoneNumber}</td>
                </tr>

                <tr>
                    <td>Address:</td>
                     <td><p>{user.address.line1}, {user.address.line2}, {user.address.city}, {user.address.postalcode}</p></td>

                </tr>
            </table>
            <p>You can update these details from you're billing portal.</p>
        </div>
    )
}