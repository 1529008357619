import Card from "react-bootstrap/Card";
import NewBooking from "../pages/accountonboarding/newBooking";
import PopupModal from "../components/PopupModal";
import { Button } from "react-bootstrap";
import { API_CLIENT } from '../api/client';
import { useEffect, useState } from "react";
import ItemToolBar from "./ItemToolBar";
import CheckoutFooter from "./CheckoutFooter";
import Checkmark from "../assets/images/check-mark.svg"




export default function ItemCard() {
    const [items, setItems] = useState([]);
    const [selectedItemIds, setSelectedItemIds] = useState([]);
  
    const [show, setShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectionMode, setSelectionMode] = useState(false);
    

    const [searchTerm, setSearchTerm] = useState('');

    
    const handleItemClick = (item) => {
      if (selectionMode) {
        if (selectedItemIds.includes(item.id)) {
          setSelectedItemIds(selectedItemIds.filter(id => id !== item.id));
        } else {
          setSelectedItemIds([...selectedItemIds, item.id]);
        }
      } else {
        setSelectedItem(item);
        setShow(true);
      }
    };
    
    function handleClose() {
      setSelectedItem(null);
      setShow(false);
    }
  
    function handleSelectButtonClick() {
      setSelectedItemIds([]);
      setSelectionMode(!selectionMode);
    }
  
    useEffect(() => {
      const fetchItems = async () => {
        setItems(await API_CLIENT.getAuthedUserItems());
      }
      fetchItems();
    }, []);
  

    const filteredItems = items.filter((item) =>
        item.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    function renderItemImage(param) {
      if (param) {
        return "https://imagedelivery.net/tR818LzxO6wAoQipdR2H9Q/" + param + "/public";
      } else {
        return 'https://via.placeholder.com/150';
      }
    }
  
    const checkBadgeStatus = (status) => {
      switch (status) {
        case "UNKNOWN":
          return "unknown"; //red
        case "ON_ROUTE":
          return "en route"; //yellow
        case "STORED":
          return "stored"; //green
        case "PENDING":
          return "pending"; //light green
        case "RETURNED":
          return "returned"; // grey
        case "Archived":
          return "archived";
      }
    }
    
    const setBadgeStyle = (status) => {
      switch (status) {
        case "UNKNOWN":
          return "storable-img-badge unknown"; //red
        case "ON_ROUTE":
          return "storable-img-badge en-route"; //yellow
        case "STORED":
          return "storable-img-badge stored"; //green
        case "PENDING":
          return "storable-img-badge pending"; //light green
        case "RETURNED":
          return "storable-img-badge returned"; // grey
        case "Archived":
          return "storable-img-badge archived";
      }
    }
    return (
      <>
        {items.length !== 0 && <ItemToolBar 
          selectionMode={selectionMode} handleSelectButtonClick={handleSelectButtonClick} setSearchTerm={setSearchTerm} searchTerm={searchTerm}
        /> }

        <div className='storable-inventory container-sm'>


        <div style={{ marginBottom: "1rem" }}>        </div>

          {items.length === 0 && <NewBooking />}
          {filteredItems.length !== 0 ? (

          filteredItems.map((item) => (
            <Card
              key={item.id}
              className={`storable-card ${selectionMode ? "selectable" : ""} ${
                selectedItemIds.includes(item.id) ? "selected" : ""
              }`}
              onClick={() => handleItemClick(item)}
              >
              <div className='storable-img-container' style={{ overflow: 'hidden' }}>
                  <img className='card-img-top storable-card-img' style={{ height: '12rem', objectFit: 'cover', borderRadius: '3px 3px 0px 0px' }} src={renderItemImage(item.image_url)} />
                  <div className={setBadgeStyle(item.status)}>
                      <p className='storable-badge-text'>{checkBadgeStatus(item.status)}</p>
                  </div>
              </div>
              <div className='card-body'>
                  <p className='storable-card-item-id' style={{ fontSize: '0.7rem' }}>{item.id.substring(28)}</p>
                  <p className='storable-card-item-desc'>{item.description}</p>
                  <div className={`${ selectedItemIds.includes(item.id) ? "selected-icon" : "select-icon" } `}>

                    <img className='check-mark' src={Checkmark}/>
                  </div>
              </div>
            </Card>
              ))
          ) : (
            <div>
            {items.length !== 0 && <p>No items fit your search</p>}
            </div>
          )}

            {selectedItem && (
              <PopupModal item={selectedItem} show={show} handleClose={handleClose}     checkBadgeStatus={checkBadgeStatus} setBadgeStyle={setBadgeStyle} />
            )}
          </div>
        <CheckoutFooter selectedItemIds={selectedItemIds} selectionMode={selectionMode} />

        </>
    )
}