import React from "react";

import { useState } from "react";
import { useEffect } from "react";
import { API_CLIENT } from "../api/client";
import Welcome from "../components/Welcome";
import Inventory from "../components/Inventory";
import UserDetails from "./accountonboarding/UserDetails";

export default function Dashboard() {
    const [user, setUser] = useState();
    const [isFetched, setIsFetched] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            setUser(await API_CLIENT.getAuthedUser());
            setIsFetched(true);
        }
        fetchUser();
    }, []);

    if (isFetched) {
        //Need to check address, phone number etc
        if (user.address == null) {
            return (
                <>
                    <UserDetails />
                </>
            )
        }
    }
    return (
        <>
            <Welcome />
            <Inventory />
        </>
    );
}