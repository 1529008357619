import React from 'react';
import { Table, Modal, Button, Form, InputGroup, FormText } from 'react-bootstrap';


const StatusLog = ({ item }) => {
  console.log(item)


  return (
    <Table striped bordered hover className='mt-3'>
      <thead>
        <tr>
          <th>On</th>
          <th>To</th>
        </tr>
      </thead>
      <tbody>
        {item.status_log.map((status, index) => (
          <tr key={index}>
            <td>{new Date(parseInt(status.on["$date"]["$numberLong"], 10)).toUTCString()}</td>
            <td>{status.to}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default StatusLog;