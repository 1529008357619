import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom"
import { API_CLIENT } from "../../api/client";

export default function User() {
    let [searchParams, _setSearchParams] = useSearchParams();
    let [userId, setUserId] = useState();
    let [user, setUser] = useState({});
    let [items, setItems] = useState([]);

    useEffect(() => {
        async function effect() {
            setUserId(searchParams.get("id"));
            if (userId == null) return;
            setUser(await API_CLIENT.getUser(userId));
            setItems(await API_CLIENT.getUserItems(userId));
        }

        effect()
    }, [userId]);
    
    if (userId == null) {
        return (
            <h1>Missing User Id</h1>
        )
    } else {
        return (
            <>
                <p>{userId}</p>
                <p>{user.name}</p>
                <p>{items.len}</p>
            </>
        )
    }
}