import { useState } from 'react';

export default function useToken() {
    const [token, setToken] = useState(sessionStorage.getItem("sessionId"));

    const saveToken = userToken => {
        sessionStorage.setItem('sessionId', userToken);
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token
    }
}