import { Container } from "react-bootstrap";
import { API_CLIENT } from "../api/client";

export default function ServerStatus() {
    if (!API_CLIENT.serverStatus){
        console.log('Failed to connect to the server.');
        return(
            <div className="alert alert-warning mb-0" role="alert">
                Failed to connect to the server. Please contact us if this issue persists.
            </div>
        )
    } else {
        console.log('Successfully connected to the server.');

    }

};  