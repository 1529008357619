import { Container } from "react-bootstrap";
import { setPassword, AuthKeyNotFound } from "../api/client"
import NewPasswordForm from "../components/NewPasswordForm";
import CasperLogoBlue from '../assets/images/CasperLogoBlue.svg';

export default function EmailVerification() {
    const searchParams = new URLSearchParams(document.location.search);
    const authId = searchParams.get("id");

    if (!authId) {
        window.location.assign('/login');
    }

    async function submitFn(password) {
        try {
            await setPassword(authId, password);

            return true;
        } catch (e) {
            if (e instanceof AuthKeyNotFound) {
                return false;
            } else {
                throw e;
            }
        }
    }

    return (
        <Container className='mt-5' style={{ textAlign: 'center' }}>
            <div className='logo mb-3'>
                <img style={{marginBottom: '20px'}} src={CasperLogoBlue} />
            </div>

            <NewPasswordForm handleSubmission={submitFn} />
        </Container>




    )
}